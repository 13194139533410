@charset "utf-8";
/* CSS Document */ 

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin:0; padding:0; }
*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
body { margin:0; padding:0; font-family: 'Poppins'; color: #241C15; -webkit-overflow-scrolling: touch;}

#root {
	overflow: hidden;
	width: 100% !important;
}

:focus { outline:none;}
ul, li, ol { list-style:none; padding: 0; margin: 0;}
a { text-decoration:none; -webkit-transition:0.5s; color: #494949; transition:0.5s;}
input[type="button"],input[type="submit"] { text-decoration:none; -webkit-transition:0.5s; transition:0.5s;}
a:hover{color:#0072ca; text-decoration: none; outline: none;}
a:focus {text-decoration: none; outline: none;}
a img, img { border:none; vertical-align:middle; max-width:100%; width:auto}

h1, h2, h3, h4, h5, h6 {font-family: 'Poppins-Bold'; margin: 0px; padding: 0px; color: #5a5c69; }
p {
	font-family: 'Poppins';
	color: #5a5c69;
	font-size: 15px;
	margin-bottom: 15px;
}

:before, :after { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box }


input,
button,
select,
textarea { font-family: inherit; font-size: inherit; line-height: inherit; }

button, 
html input[type="button"], 
input[type="reset"], 
input[type="submit"] { cursor: pointer;  outline: none !important; text-decoration: none !important;}

button::-moz-focus-inner, 
input::-moz-focus-inner {  padding: 0;  border: 0; }

input[type="checkbox"], 
input[type="radio"] { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; padding: 0; }

input[type="search"],
input[type="text"],
textarea { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;  }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {  -webkit-appearance: none;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none;   margin: 0;}


table { border-spacing: 0; border-collapse: collapse; }

.row:before, .row:after {display: inline-block !important;}

.container {max-width: 1200px; width: 100%; margin:0 auto; padding: 0px 15px;}
.container:before, 
.container:after { display:table; content:"";}
.container:after { clear:both;}

.container-fluid {max-width: 100%; width: 100%; margin:0 auto; padding: 0px 20px;}
.container-fluid:before, 
.container-fluid:after { display:table; content:"";}
.container-fluid:after { clear:both;}

.menu-icon { display:none; height:auto; padding:7px 0 7px 0; width:40px; background:rgba(0,0,0,0.2);}
.menu-icon span { display:block; margin:4px 10px; background:#fff; height:3px;}

.videowapper {
	/* width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative; */

	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
}
video {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  width: 100% !important;
	  height: 100% !important;
	  object-fit: contain;
	  transform: translate(-50%, -50%);
	  background-color: #000;
}


div[data-column-id="1"] {
	width: 100px !important;
	max-width: 100px !important;
}
div[data-column-id="2"] {
	width: 100% !important;
	max-width: calc(100% - 200px) !important;
}
div[data-column-id="3"] {
	width: 100px !important;
	max-width: 100px !important;
}

.btn.btn-green {
	background: #6bc86b;
	color: #fff;
	font-size: 20px;
	width: 50px;
	height: 50px;
	border-radius: 8px;
	display: flex;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}
.btn.btn-green svg {
	display: block;
}